import { UserManagerSettings } from 'oidc-client';

export function _mapProviderSettings(_provider: 'AzureV1' | 'Akana') {
    let settings = {};

    switch (_provider) {
        case 'Akana':
            settings = {
                response_type: 'token',
                scope: ' ',
            };
            break;
        case 'AzureV1':
            settings = {
                response_type: 'token',
                authority: 'https://login.microsoftonline.com/a25fff9c-3f63-4fb2-9a8a-d9bdd0321f9a/.well-known/openid-configuration',
                scope: ' ',
                extraQueryParams: {
                    domain_hint: 'mayo.edu'
                }
            };
            break;
        default:
            settings = {
                response_type: 'token',
                scope: ' ',
            };
            break;
    }
    return <UserManagerSettings>settings;
}
    // return new Map<number, {}>([
    //     [Provider.Akana, {
    //         response_type: 'token',
    //         scope: 'openid',
    //     }],
    //     [Provider.Azure, {
    //         response_type: 'token id_token',
    //         scope: ' ',
    //         extraQueryParams: {
    //             domain_hint: 'mayo.edu'
    //         }
    //     }],
    //   ])