import { compose } from "ramda";

import {
  usePostMethod,
  withJsonBody,
  useDeleteMethod,
  usePatchMethod
} from "research-go-shared/lib/util/fetch-helpers";

import { Announcement } from "./model";
import { AnnouncementFilter } from "../home/model";

export const announcementFunctions = f => ({
  addAnnouncement: body => {
    const options = compose(usePostMethod, withJsonBody(body))({});
    return f("/api/announcement", options);
  },
  getMyAnnouncements: (
    filter?: AnnouncementFilter = "unread"
  ): Promise<Announcement[]> => {
    const dismissed = filter === "unread" ? [false] : [true, false];
    const filterParam = dismissed.map(x => "dismissed=" + x).join("&");

    return f(`/api/me/announcement?${filterParam}`).then(x => x.json());
  },
  getAllAnnouncements: (filter: boolean[]): Promise<Announcement[]> => {
    const filterParam = filter.map(x => "dismissed=" + x).join("&");
    return f(`/api/announcement?${filterParam}`).then(x => x.json());
  },
  dismissAnnouncement: (a: Announcement): Promise<unknown> => {
    const options = usePostMethod({});
    return f(`/api/me/announcement/${a.announcementId}/dismissal`, options);
  },
  removeDismissal: (a: Announcement): Promise<unknown> => {
    const options = useDeleteMethod({});
    return f(`/api/me/announcement/${a.announcementId}/dismissal`, options);
  },
  setAnnouncementDismissal: (
    a: Announcement,
    dismissed: boolean
  ): Promise<unknown> => {
    const options = compose(
      usePatchMethod,
      withJsonBody({ announcementId: a.announcementId, dismissed })
    )({});
    return f(`/api/announcement/${a.announcementId}`, options);
  },
  getTrouble: (): Promise<any> => {
    return f(`/api/icontrouble`).then(res => res.json());
  }
});
