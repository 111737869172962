(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("oidc-client"));
	else if(typeof define === 'function' && define.amd)
		define(["oidc-client"], factory);
	else if(typeof exports === 'object')
		exports["mayoJsOidc"] = factory(require("oidc-client"));
	else
		root["mayoJsOidc"] = factory(root["OidcClient"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_oidc_client__) {
return 