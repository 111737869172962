import events from "./events";
import {
  compose,
  concat,
  filter,
  find,
  isNil,
  map,
  not,
  prop,
  propEq,
  propOr,
  uniq
} from "ramda";
import {
  FeedbackPayload,
  FeedbackItem,
  FeedbackNote,
  FeedbackDiscussionItem
} from "./model";
import { FeedbackService } from "./service";
import { Emitter } from "research-go-shared/lib/choo-modules/event-emitter";
import { unknownUserUrl } from "research-go-shared/lib/components/user-card";
import {
  Futurish,
  Complete,
  Waiting,
  Err
} from "useful-webapp-monads/dist/futurish";

export const feedbackEventListeners = (
  setState: (s: Futurish<FeedbackPayload, Error> | null) => void,
  emitter: Emitter,
  svc: any
) => {
  emitter.on("DOMContentLoaded", () => {});
  emitter.on(events.feedbackSubmitted, async (payload: FeedbackPayload) => {
    setState(Waiting);
    try {
      const result = await svc.CreateFeedback(payload);
      emitter.emit(events.feedbackSuccess, payload);
    } catch (e) {
      emitter.emit(events.feedbackError, e);
    }
  });
  emitter.on(events.feedbackSuccess, (payload: FeedbackPayload) => {
    setState(Complete(payload));
    setTimeout(() => setState(null), 3000);
  });
  emitter.on(events.feedbackError, () => {
    setState(Err(new Error("Failed to submit feedback.")));
  });
};

const betterPhotoGetter = async (fn: any, id: string) => {
  try {
    return await fn(id);
  } catch (e) {
    return unknownUserUrl;
  }
};

export async function feedbackReviewEventHandlers(
  setState: (x: Futurish<FeedbackItem[], Error>) => void,
  svc,
  getUser,
  getPhoto,
  emitter: Emitter
) {
  emitter.on(events.loadFeedbackList, async showAll => {
    try {
      const stuff: any[] = await (showAll
        ? svc.getAllFeedback()
        : svc.GetFeedbackList());
      const findUniq = lens =>
        compose(
          filter(
            compose(
              not,
              isNil
            )
          ),
          uniq,
          map(lens)
        )(stuff);
      const uniqueIds = findUniq(prop("emailAddress"));
      const uniqueAddressedByIds = findUniq(prop("addressedByEmailAddress"));
      const allUniqueIds = uniq(concat(uniqueIds, uniqueAddressedByIds));

      const profiles = await Promise.all(
        allUniqueIds.map(async id => {
          const photoP = betterPhotoGetter(getPhoto, id);
          const userP = await getUser(id);
          return {
            emailAddress: id,
            displayName: userP.displayName,
            photoUrl: await photoP,
            businessPhones: userP.businessPhones
          };
        })
      );

      const findDisplayName = em =>
        propOr(
          "Anonymous",
          "displayName",
          find(propEq("emailAddress", em), profiles)
        );
      const findPhoto = em =>
        propOr(
          unknownUserUrl,
          "photoUrl",
          find(propEq("emailAddress", em), profiles)
        );
      const findBusinessphones = em =>
        propOr(
          [],
          "businessPhones",
          find(propEq("emailAddress", em), profiles)
        );

      const feedbackThings: FeedbackItem[] = stuff.map(item => {
        return {
          displayName: findDisplayName(item.emailAddress),
          photoUrl: findPhoto(item.emailAddress),
          feedback: item.message,
          date: new Date(item.dateCreated),
          feedbackId: item.feedbackId,
          addressedOn: item.addressedOn,
          addressedBy: {
            displayName: findDisplayName(item.addressedByEmailAddress),
            photoUrl: findPhoto(item.addressedByEmailAddress)
          },
          discussionCount: item.discussionCount,
          emailAddress: item.emailAddress,
          businessPhones: findBusinessphones(item.emailAddress)
        };
      });
      setState(Complete(feedbackThings));
    } catch (err) {
      setState(Err(err));
    }
  });
  setState(Waiting);
  emitter.emit(events.loadFeedbackList);
}

export const feedbackNotesEventHandlers = (
  emitter: Emitter,
  onNotesLoaded: (
    feedbackId: string,
    notes: Futurish<FeedbackDiscussionItem[], Error>
  ) => void,
  svc: FeedbackService,
  setSelectedFeedback: (id: number) => void,
  getUserProfile,
  getPhoto
) => {
  let intervalId = null;
  emitter.on(events.selectDiscussionFor, feedbackId => {
    onNotesLoaded(feedbackId, Waiting);
    setSelectedFeedback(feedbackId);
    intervalId = setInterval(
      () => emitter.emit(events.loadNotesFor, feedbackId),
      5000
    );
    emitter.emit(events.loadNotesFor, feedbackId);
  });
  emitter.on(events.loadNotesFor, async feedbackId => {
    try {
      const notes = await svc.getNotes(feedbackId);
      const notesWithUserInfo: FeedbackDiscussionItem[] = await Promise.all(
        notes.map(async n => ({
          ...n,
          user: {
            displayName: (await getUserProfile(n.emailAddress)).displayName,
            photoUrl:
              (await betterPhotoGetter(getPhoto, n.emailAddress)) ||
              unknownUserUrl
          }
        }))
      );
      emitter.emit(events.notesLoadedFor, {
        feedbackId,
        notes: notesWithUserInfo
      });
    } catch (err) {
      console.log("Failed to load notes" + err);
    }
  });

  emitter.on(events.notesLoadedFor, ({ feedbackId, notes }) =>
    onNotesLoaded(feedbackId, Complete(notes))
  );

  emitter.on(events.clearSelectedFeedback, () => {
    setSelectedFeedback(null);
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  });

  emitter.on(events.addNote, async ({ feedbackId, note }) => {
    await svc.addNote({
      feedbackId,
      note
    });

    emitter.emit(events.loadNotesFor, feedbackId);
  });

  emitter.on(events.markAsAddressed, async feedbackId => {
    await svc.markAsAddressed({
      feedbackId,
      addressedOn: new Date().toISOString()
    });
    emitter.emit(events.loadFeedbackList);
  });
};
