import "@babel/polyfill";
import "whatwg-fetch";
import { pathOr } from "ramda";

import { localizedFetch } from "research-go-shared/lib/util/fetch-helpers";
import { notifyListeners } from "research-go-shared/lib/choo-modules/notify";

import "@element/dialog/lib/styles.css";

import { TokenInfo } from "mayo-js-oidc";
import { addIeWarning } from "@partially-practical/ie-warn";
import { withAdapter, fetchAdapter } from "azure-user-profile";
import { bootstrapModules } from "research-go-shared/lib/choo-modules/bootstrap";
import { wireUpServiceWorkerCache } from "./service-worker-manage";
import {
  home,
  bell,
  feedback,
  listIcon
} from "research-go-shared/lib/icons";
import Choo from "choo";
import html from "nanohtml";
import "research-go-shared/src/choo-modules/app-framing.scss";
import "./home/home.scss";

import { feedbackModule } from "./feedback";
import { initAuth } from "research-go-shared/lib/auth";
import { initConfig } from "research-go-shared/lib/choo-modules/app-context";
import framingModule, { toLink } from "research-go-shared/lib/choo-modules/app-framing";
import { AppModule } from "research-go-shared/lib/choo-modules/module";
import { Emitter } from "research-go-shared/lib/choo-modules/event-emitter";

import { serviceModule } from "./service";
import { undosModule } from "research-go-shared/lib/undos/module";
import { addWatermark } from "research-go-shared/lib/util/watermark";
import { useDevTools } from "./dev-tools";
import { HomeState } from "./home/model";
import { ticketModule } from "./ticket";
import { ResearchGoConfig } from "./researchGoConfig/model";
import { homeModule } from "./home";


async function startup() {
  addIeWarning();
  const config = await initConfig<ResearchGoConfig>("researchGo");

  addWatermark(config.watermark);

  const tokenResult: { [key: string]: TokenInfo } = await initAuth(
    config.tokenConfig
  );

  await wireUpServiceWorkerCache(
    (e) => console.log("EVENT FROM SW", e),
    (e, cb) => {
      console.log("Registered event listener", e, cb);
    }
  );
  const graphToken = tokenResult["MS-Graph"];
  const researchGoToken = tokenResult["ResearchGo"];
  const researchGoFetch = localizedFetch(
    config.researchGoApiRoot,
    config.tokenConfig.find((x) => x.name === "ResearchGo"),
    () => {},
    () => {}
  )(fetch);

  const mirisFetch = localizedFetch(
    config.mirisApiRoot,
    config.tokenConfig.find((x) => x.name === "ResearchGo"),
    () =>{},
    ()=>{}
  )(fetch);

  const isAdmin =
    pathOr([], ["accessTokenClaims", "roles"], researchGoToken).indexOf(
      "app.admin"
    ) >= 0;

  const isAIReviewer =
    pathOr([], ["accessTokenClaims", "roles"], researchGoToken).indexOf(
      "app.aireviewer"
    ) >= 0;

  const isEpicenter = true;

  if (config.features.includeDevTools) {
    useDevTools(researchGoFetch);
  }

  if (config.appDynamics) {
    window["adrum-config"] = {
      ...window["adrum-config"],
      ...config.appDynamics.config,
    };
    try {
      await import(config.appDynamics.scriptUrl);
    } catch (e) {
      console.log("Failed to load app dynamics. Analytics disabled");
    }
  }

  const { getUserProfile, getUserPhoto } = withAdapter(
    fetchAdapter(graphToken.access_token)
  );

  const app = new Choo({
    hash: false,
  });

  app.use((state, emitter) => notifyListeners(emitter));

  const [profile, photo] = await Promise.all([
    getUserProfile(),
    getUserPhoto(),
  ]);

  app.use(async (state, emitter) => {
    state.userContext = {
      photo,
      userName: profile.displayName,
      jobTitle: profile.jobTitle,
      givenName: profile.givenName,
    };
  });

  const modules: { [key: string]: AppModule<any> } = {
    undos: undosModule,
    framing: framingModule,
    feedback: feedbackModule(
      researchGoFetch,
      () => graphToken.access_token,
      () => isAdmin
    ),
    home: homeModule(
      () => app.state.userContext,
      config.appUrls,
      researchGoFetch,
      isAdmin,
      isAIReviewer,
      isEpicenter
    ),
    service: await serviceModule(researchGoFetch),
    tickets: ticketModule(researchGoFetch, config.serviceNowUrl),
  };

  if (isAdmin) {
    try {
      modules.admin = (await import("./admin")).adminModule(researchGoFetch, mirisFetch);
      console.log("ADMIN MODULE BOOTSTRAPPED");
    } catch (e) {
      console.error("Failed to load admin module", e);
    }
  }

  const getAppContext = () => ({
    tokens: tokenResult,
    onLine: true,
    appConfig: config,
  });

  const announcementLink = (state: { home: HomeState }, emit) => {
    const announcementCount = state.home.announcements.list.eval(
      (a) => a.length,
      () => 0,
      () => 0
    );
    return toLink("/research-go")(emit)(() => false)({
      href: "/home/announcements",
      icon: bell(),
      name: html`
        <span class="whats-new" data-count=${announcementCount}
          >What's new</span
        >
      `,
    });
  };

  const feedbackLink = (emit) =>
    toLink("/research-go")(emit)(() => false)({
      href: "/feedback",
      icon: feedback(),
      name: "Feedback",
    });

  const serviceLink = (emit) =>
    toLink("/research-go")(emit)(() => false)({
      href: "/tickets",
      icon: listIcon(),
      name: "Tickets",
    });

  bootstrapModules(
    modules,
    app,
    getAppContext,
    "/research-go",
    (s) => s.framing,
    (s) => s.undos.undos,
    (state, emit) => [
      announcementLink(state, emit),
      serviceLink(emit),
      feedbackLink(emit),
    ],
    (s) => s.userContext,
    "research-go-app"
  );

  if (process.env.NODE_ENV === "development") {
    const devtools = await import("choo-devtools");

    app.use(devtools());
  }

  app.use(
    (state, emitter: Emitter) => (
      trackLastUrl(emitter), setUpBottomLinks(emitter)
    )
  );

  app.route("*", function (state, emit) {
    return html` <div></div> `;
  });

  app.use((state, emitter) => {
    emitter.on("DOMContentLoaded", function () {
      setTimeout(() => {
        emitter.emit("navigate", state.href);
      }, 100);
    });
  });

  app.use((state, emitter) => {
    emitter.on("navigate", () => {
      const matchedRoute = (app as any).router.match(state.href);
      if (matchedRoute.route === "*") {
        emitter.emit("replaceState", "/research-go/home");
      }
    });
  });

  app.mount("#research-go-app");
}

const setUpBottomLinks = (emitter: Emitter) => {
  emitter.emit("register-page", {
    name: "Home",
    icon: home(),
    href: "/home",
  });
};

const trackLastUrl = (emitter: Emitter) => {
  emitter.on("DOMContentLoaded", function () {
    const lastUrl = sessionStorage.getItem("research-go-choo-last-url");

    if (lastUrl) {
      if (!window.location.href.includes(lastUrl)) {
        emitter.emit("replaceState", lastUrl);
      }
    }

    emitter.on("pushState", function (url: string) {
      sessionStorage.setItem("research-go-choo-last-url", url);
    });
  });
};
startup();
