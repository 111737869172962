import { TokenManager } from './token-manager';
import { UserManagerSettings } from 'oidc-client';
import { TokenInfo } from './token-info';

export function initOneSynchronous (settingsList: UserManagerSettings[], returnValues: TokenInfo[]): Promise<TokenInfo[]> {
    return InitFuncs.initOne(settingsList[0]).then(tokenInfo => {
        returnValues.push(tokenInfo);
        if(settingsList.length === 1) {
            return returnValues;
        }
        let remainingSettingsList = settingsList.slice(1);
        return initOneSynchronous(remainingSettingsList, returnValues);
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

export function getWindow() {
    return window;
}

export function createSigninRedirectPromise(timeout: number) {
    return new Promise<TokenInfo>((resolve, reject) => {
        setTimeout(() => reject(new Error('Redirect timed out')), timeout);
    });
}

/**
 * Initialize your authentication process via OAuth2 implicit flow 
 * and OpenID Connect to handle one or many tokens.
 * @param {UserManagerSettings[]} settingsList Array of one or many OpenID Connect/OAuth2 setting objects
 * @return {Promise<TokenManager[]>} A Promise for a TokenManager array
 * with access to User objects that contain token information such as
 * claims, session state, etc.
 */
export function initMany(settingsList: UserManagerSettings[]): Promise<TokenInfo[]>  {
    if(settingsList.length > 0) {
        return initOneSynchronous(settingsList, []);
    }
    else {
        return Promise.resolve(null);
    }
}

// 1. check to see if we already have a token
// 2. if we already have a valid token, return it
// 3. if not, then go get a token then return it
// 4. if errors along the way, return a Promise.reject

/**
 * Initialize your authentication process via OAuth2 implicit flow 
 * and OpenID Connect to handle one or many tokens.
 * @param {UserManagerSettings} settings A settings object to 
 * configure OAuth2 and OpenID Connect
 * @return {Promise<TokenManager>} A Promise for a TokenManager
 * with access to a User object that contains token information such as
 * claims, session state, etc.
 */
export function initOne(settings: UserManagerSettings): Promise<TokenInfo> {
    let tokenMgr: TokenManager;

    // 1. check if settings were passed in
    if (settings) {
        tokenMgr = new TokenManager(settings);
        return tokenMgr.getUser().then(storedUser => {
            // 2. check if already authenticated, if so return the user and token
            if (storedUser != null && !storedUser.expired) {
                return new TokenInfo(storedUser, tokenMgr);
            } else {
                // 3. check if token should authenticate silently and if the token
                // has already come through - if so then redirect silently to 
                // refresh token
                if (tokenMgr.settings.authenticateSilently || (storedUser && tokenMgr.settings.silent_redirect_uri)) {
                    return tokenMgr.signinSilent().then(user => { 
                        return new TokenInfo(user, tokenMgr);
                    });
                } else {
                    // 4. if not authenticating silently and has not gotten a
                    // valid token, redirect to acquire token.  If the access token 
                    // is in the url, call signinRedirectCallback,
                    // otherwise call signinRedirect
                    // (making an assumption these match at the beginning)
                    return tokenMgr.settings._stateStore.getAllKeys().then(keys => {
                        if (keys.length > 0 && InitFuncs.getWindow().location.href.startsWith(tokenMgr.settings.redirect_uri)) {
                            return tokenMgr.signinRedirectCallback().then(user => {
                                return new TokenInfo(user, tokenMgr);
                            });
                        } else {
                            return tokenMgr.clearStaleState().then(() => {
                                return tokenMgr.signinRedirect().then(() => {
                                    return InitFuncs.createSigninRedirectPromise(120000);
                                });
                            });
                        }
                    });
                }
            }
        });
    } else {
        return Promise.reject(new Error('No settings found'));
    }
}

/* tslint:disable:variable-name */
export const InitFuncs = { initOne, initMany, getWindow, createSigninRedirectPromise };