import { UserManagerSettings, UserManager, User } from 'oidc-client';
import { _mapProviderSettings } from './identity-provider-mapping';
import { _validateIdToken } from './no-signing-key-response-validator';
import * as oidc from '../typings/index';

export class TokenManager extends UserManager {

    constructor(managerSettings: UserManagerSettings) {
        super(TokenManager.combineManagerSettings(managerSettings));

        // set custom augmented properties outside of super because
        // they are not recognized in the super when extended to OidcClientSettings
        this.settings.name = managerSettings.name || 'user';
        this.settings.authenticateSilently =
            managerSettings.authenticateSilently ? managerSettings.authenticateSilently : false;

        if (managerSettings.ResponseValidatorCtor === undefined) {
            this.overrideValidateIdTokenMethod();
        }
    }    

    // override the _userStoreKey property on the UserManager prototype
    // because this is not exposed in the TypeScript definition and we want
    // to add the token 'name' to the key in session storage to better differentiate
    // tokens
    get _userStoreKey() {
        return `${this.settings.name}:${this.settings.authority}:${this.settings.client_id}`;
    }

    private static combineManagerSettings(userSpecifiedSettings: UserManagerSettings) {
        let defaultProviderSettings = _mapProviderSettings(userSpecifiedSettings.defaultProviderSettings);
        // must apply the spread operator to nested object extraQueryParams or 
        // userSpecifiedSettings will overwrite all extraQueryParams for defaultProviderSettings
        userSpecifiedSettings.extraQueryParams = {...defaultProviderSettings.extraQueryParams, ...userSpecifiedSettings.extraQueryParams};
        return <UserManagerSettings>{
            ...{
                accessTokenExpiringNotificationTime: 300,
                automaticSilentRenew: true,
                includeIdTokenInSilentRenew: false,
                filterProtocolClaims: false,
                loadUserInfo: false,
                staleStateAge: 0,
                monitorSession: false
            },
            ...defaultProviderSettings,
            ...userSpecifiedSettings,
        };
    }

    /**
     * Public key validation cannot be done since the Azure public key URL doesn't support CORS headers
     * Therefore, override the method _validateIdToken so CORS validation isn't done when a user logs in
     */
    overrideValidateIdTokenMethod() {
        let prototype = Object.getPrototypeOf(this.settings.validator);
        prototype['_validateIdToken'] = _validateIdToken;
    }

    parseJwt(jwt: string) {
        return this.settings.validator._joseUtil.parseJwt(jwt);
    }
}