import html from "choo/html";
import { ViewState } from "research-go-shared/lib/choo-modules/module";
import { TicketState, ServiceTicket } from "./model";
import { Futurish } from "useful-webapp-monads/dist/futurish";
import { externalLink } from "research-go-shared/lib/icons";
import { Emit } from "research-go-shared/lib/choo-modules/event-emitter";
import events from "./events";
import { formatDateTime } from "research-go-shared/lib/util/dates";
import { bouncyLoader } from "research-go-shared/lib/components/loader";
import { defaultErrorMsg } from "research-go-shared/lib/components/error-msg";
import { sortBy, prop, reverse } from "ramda";

const renderItem = (item: ServiceTicket, serviceNowUrl: string, emit: Emit) => {
  return html`
    <li>
      <h3>
        Incident: ${item.ticketNumber}<button
          id="${item.ticketNumber}"
          class="mcui-btn"
          onclick=${() =>
            emit(events.loadExternalLink, {
              serviceNowUrl,
              serviceTicket: item
            })}
        >
          ${externalLink()}
        </button>
      </h3>
      <div>${formatDateTime(item.dateCreated)}</div>
      <div>${item.description}</div>
    </li>
  `;
};
const renderItems = (
  items: ServiceTicket[],
  serviceNowUrl: string,
  emit: Emit
) => {
  const sorter = sortBy(prop("dateCreated"));
  const sorted = sorter(items);
  return reverse(sorted).map((x: ServiceTicket) =>
    renderItem(x, serviceNowUrl, emit)
  );
};

const buildTicketList = (
  possibleData: Futurish<ServiceTicket[], Error>,
  emit: Emit,
  serviceNowUrl: string
) =>
  possibleData.eval(
    x => html`
      <ul>
        ${renderItems(x, serviceNowUrl, emit)}
      </ul>
    `,
    () => bouncyLoader(),
    () => defaultErrorMsg("Unable to load tickets"),
    () => {
      emit(events.loadTickets);
      return bouncyLoader();
    }
  );

export const provideTicketView = (serviceNowUrl: string) => {
  return (input: ViewState<TicketState>) => {
    const statusPending = input.getState().ticketList;
    return html`
      <div id="ticket-view">
        <h1>My Tickets</h1>
        ${buildTicketList(statusPending, input.emit, serviceNowUrl)}
      </div>
    `;
  };
};
