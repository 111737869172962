import html from "nanohtml";
import htmlRaw from "nanohtml/raw";
import { markdown } from "markdown";
import {
  circleExclamation,
  close,
  bell,
  warning
} from "research-go-shared/lib/icons";
import { Announcement } from "./model";

const icons = {
  Warning: circleExclamation,
  Information: bell,
  Error: warning
};
const noIcon = () =>
  html`
    <svg></svg>
  `;

type dismissFn = (a: Announcement) => void;

export const toAnnouncementLi = (
  dismissAnnouncement: dismissFn,
  additionalContent: (a: Announcement) => HTMLElement | string = () => ""
) => (a: Announcement) => {
  const announcementBody = htmlRaw(markdown.toHTML(a.message));
  const dismissButton = a.dismissed
    ? ""
    : html`
        <button class="dismiss" onclick=${() => dismissAnnouncement(a)}>
          ${close()}
        </button>
      `;
  return html`
    <li class="announcement ${a.announcementType}">
      ${(icons[a.announcementType] || noIcon)()}
      <header>${a.title || a.announcementType}</header>
      <div class="announcement-body">
        ${announcementBody} ${additionalContent(a)}
      </div>
      ${dismissButton}
    </li>
  `;
};

export const announcementsView = (
  dismissAnnouncement: dismissFn,
  additionalContent: (a: Announcement) => HTMLElement | string = () => ""
) => (announcements: Announcement[]) => {
  return html`
    <ul>
      ${announcements.map(
        toAnnouncementLi(dismissAnnouncement, additionalContent)
      )}
    </ul>
  `;
};
