import * as LocalTypings from '../typings/index';
import { Log, ResponseValidator } from 'oidc-client';

export function _validateIdToken(state: any, response: any) {
    // need to disable tslint rule here as "this" should be a ResponseValidator when this function is added to it's prototype
    /* tslint:disable:no-invalid-this */
    let _this = <ResponseValidator>this;
    if (!state.nonce) {
        Log.error('ResponseValidator._validateIdToken: No nonce on state');
        return Promise.reject(new Error('No nonce on state'));
    }

    let jwt = _this._joseUtil.parseJwt(response.id_token);
    if (!jwt || !jwt.header || !jwt.payload) {
        Log.error('ResponseValidator._validateIdToken: Failed to parse id_token', jwt);
        return Promise.reject(new Error('Failed to parse id_token'));
    }

    if (state.nonce !== jwt.payload.nonce) {
        Log.error('ResponseValidator._validateIdToken: Invalid nonce in id_token');
        return Promise.reject(new Error('Invalid nonce in id_token'));
    }

    return _this._metadataService.getIssuer().then(issuer => {
        Log.debug('ResponseValidator._validateIdToken: Received issuer');

        let audience = state.client_id;
        let clockSkew = _this._settings.clockSkew;

        let jwtPayload = _this._joseUtil.parseJwt(response.id_token).payload;

        if (!clockSkew) {
            clockSkew = 0;
        }

        let now = Date.now() / 1000;

        var payload = jwt.payload;

        if (!payload.iss) {
            Log.error('JoseUtil._validateJwt: issuer was not provided');
            return Promise.reject(new Error('issuer was not provided'));
        }
        if (payload.iss !== issuer) {
            Log.error('JoseUtil._validateJwt: Invalid issuer in token', payload.iss);
            return Promise.reject(new Error('Invalid issuer in token: ' + payload.iss));
        }
        if (!payload.aud) {
            Log.error('JoseUtil._validateJwt: aud was not provided');
            return Promise.reject(new Error('aud was not provided'));
        }
        var validAudience = payload.aud === audience || (Array.isArray(payload.aud) && payload.aud.indexOf(audience) >= 0);
        if (!validAudience) {
            Log.error('JoseUtil._validateJwt: Invalid audience in token', payload.aud);
            return Promise.reject(new Error('Invalid audience in token: ' + payload.aud));
        }

        var lowerNow = now + clockSkew;
        var upperNow = now - clockSkew;

        if (!payload.iat) {
            Log.error('JoseUtil._validateJwt: iat was not provided');
            return Promise.reject(new Error('iat was not provided'));
        }
        if (lowerNow < payload.iat) {
            Log.error('JoseUtil._validateJwt: iat is in the future', payload.iat);
            return Promise.reject(new Error('iat is in the future: ' + payload.iat));
        }

        if (payload.nbf && lowerNow < payload.nbf) {
            Log.error('JoseUtil._validateJwt: nbf is in the future', payload.nbf);
            return Promise.reject(new Error('nbf is in the future: ' + payload.nbf));
        }

        if (!payload.exp) {
            Log.error('JoseUtil._validateJwt: exp was not provided');
            return Promise.reject(new Error('exp was not provided'));
        }
        if (payload.exp < upperNow) {
            Log.error('JoseUtil._validateJwt: exp is in the past', payload.exp);
            return Promise.reject(new Error('exp is in the past: ' + payload.exp));
        }

        response.profile = jwt.payload;

        return Promise.resolve(response);
    });
}