import { User, SigninResponse } from 'oidc-client';
import { TokenManager } from './token-manager';

export class TokenInfo extends User {
    constructor(private user: User, private tokenMgr: TokenManager) {       
        super(<any>{...user});


    }

    get tokenManager() {
        return this.tokenMgr;
    }

    get idTokenClaims() {
        return this.tokenMgr.parseJwt(this.id_token).payload;
    }

    get accessTokenClaims() {
        return this.tokenMgr.parseJwt(this.access_token).payload;
    }
}