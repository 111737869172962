import Nanocomponent from "choo/component";

import html from "choo/html";
import { FeedbackPayload } from "../model";
import { circleExclamation } from "research-go-shared/lib/icons";
import { Futurish, Complete } from "useful-webapp-monads/dist/futurish";

const colors = ["red", "orange", "yellow", "green", "blue", "violet"];

export class FeedbackForm extends Nanocomponent {
  private currentBorder = 0;
  private text = "";
  private anon = false;
  private hasBeenReset = false;
  private error = null;

  constructor() {
    super();
  }

  private updateBorderColor(e) {
    this.text = e.target.value;
    this.currentBorder = (this.currentBorder + 1) % colors.length;
    this.rerender();
  }

  public createElement(
    data = { onSubmit: (p: FeedbackPayload) => {}, feedbackInfo: null }
  ) {
    const onSubmit = data.onSubmit;
    const feedbackInfo: Futurish<FeedbackPayload, Error> = data.feedbackInfo;

    if ((Complete as any).is(feedbackInfo) && !this.hasBeenReset) {
      this.text = "";
      this.anon = false;
      this.hasBeenReset = true;
    }
    const doSubmit = e => {
      e.preventDefault();
      if (!this.text || this.text.trim().length === 0) {
        this.error = "Please provide a message";
        this.rerender();
      } else if (this.text.length > 4000) {
        this.error = `Please limit your message to 4000 characters (currently ${
          this.text.length
        })`;
        this.rerender();
      } else {
        this.error = null;
        this.hasBeenReset = false;
        onSubmit({ message: this.text, isAnonymous: this.anon });
      }
      return false;
    };

    const getCurrentBorder = () =>
      process.env.NODE_ENV === "development"
        ? colors[this.currentBorder]
        : "#8d9aa9";

    const input = html`
      <textarea
        value="${this.text}"
        class="${this.error ? "error" : ""}"
        style="border-color: ${getCurrentBorder()}"
        oninput=${this.updateBorderColor.bind(this)}
      ></textarea>
    ` as HTMLTextAreaElement;
    input.value = this.text;

    const errorMsg = this.error
      ? html`
          <div class="error-msg">${circleExclamation()}${this.error}</div>
        `
      : html`
          <div class="error-msg"></div>
        `;

    const updateAnon = e => {
      this.anon = e.target.checked;
    };

    const anonCb = html`
      <input type="checkbox" onchange=${updateAnon} checked=${this.anon} />
    ` as HTMLInputElement;

    return html`
      <div>
        <form onsubmit=${doSubmit}>
          ${input} ${errorMsg}
          <label class="mcui-checkbox"
            >${anonCb}
            <div>
              <svg class="mcui-check" viewBox="-2 -2 35 35">
                <polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
              </svg>
            </div>
            <div>Post anonymously</div>
          </label>
          <button class="mcui-btn primary" type="submit">Submit</button>
        </form>
      </div>
    `;
  }

  public update() {
    return true;
  }
}
